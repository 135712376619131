
<template>
  <div class="divBox">
    <div class="cardBox">
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{ details.goodsAmount || 0 }}</div>
          <div>应收金额</div>
        </div>
      </el-card>
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{ details.payAmount || 0 }}</div>
          <div>实付金额</div>
        </div>
      </el-card>
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{ details.discountPrice || 0 }}</div>
          <div>抵扣金额</div>
        </div>
      </el-card>
      <el-card class="elCard">
        <div class="box">
          <div class="price">{{ details.quantity || 0 }}</div>
          <div>订货数量</div>
        </div>
      </el-card>
    </div>

    <el-card style="margin-bottom: 20px">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="85px"
      >
        <div>
          <el-form-item label="" prop="selectType">
            <el-radio-group
              v-model="tableFrom.selectType"
              type="button"
              @change="getList"
            >
              <div style="display: flex">
                <el-radio-button label="1">我的申请</el-radio-button>
                <el-radio-button label="2">待办对账单</el-radio-button>
                <el-radio-button label="3">已办对账单</el-radio-button>
              </div>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="供应商：" prop="supplierId">
            <el-select
              v-model="tableFrom.supplierId"
              placeholder="请选择"
              class="selWidth"
              filterable
            >
              <el-option
                v-for="(item, index) in supplierList"
                :key="index"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="订单号：" prop="orderId">
            <el-input
              v-model="tableFrom.orderId"
              placeholder="请输入订单号"
              class="input-with-select selWidth"
              clearable
            />
          </el-form-item>
          <el-form-item label="	对账单号：" prop="financeId">
            <el-input
              v-model="tableFrom.financeId"
              placeholder="请输入对账单号"
              class="input-with-select selWidth"
              clearable
            />
          </el-form-item>
          <el-form-item label="审核状态:" prop="checkStatus">
          <el-select
            v-model="tableFrom.checkStatus"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in checkStatusOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> 
        </el-form-item>

        <el-form-item label="打款状态:" prop="payStatus">
          <el-select
            v-model="tableFrom.payStatus"
            placeholder="请选择"
            class="filter-item selWidth"
            clearable
          >
            <el-option
              v-for="item in payStatusOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

          <el-form-item label="提交时间：" prop="time">
            <el-date-picker
              v-model="tableFrom.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="small"
              @click="search(), getDateDetails()"
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
            <el-button size="small" class="mb10" @click="exportFun()"
              >导出</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div class="mb20 acea-row">
        <el-button
          v-if="tableFrom.selectType == 2"
          size="small"
          type="primary"
          class="mb10"
          @click="batchExamine(1)"
          >批量通过</el-button
        >
        <el-button
          v-if="tableFrom.selectType == 2"
          size="small"
          type="primary"
          class="mb10"
          @click="batchExamine(2)"
          >批量驳回</el-button
        >
        <el-button
          v-if="tableFrom.selectType == 2"
          size="small"
          type="primary"
          class="mb10"
          @click="batchExamine(3)"
          >批量打款</el-button
        >
      </div>
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="id"
        :default-expand-all="false"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column key="2" type="selection" width="55" />
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
        <el-table-column label="对账单号" prop="code" min-width="80">
        </el-table-column>
        <el-table-column label="提交时间" prop="createTime" min-width="80">
        </el-table-column>
        <el-table-column label="商品名称" prop="goodsName" min-width="300">
          <template slot-scope="scope">
            <div v-if="scope.row.goodsName">
              <div
                v-for="(item, index) in scope.row.goodsName.split(',')"
                :key="index"
              >
                {{ item }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品订单号" prop="orderCode" min-width="80">
        </el-table-column>
        <el-table-column label="审核状态" prop="sentQuantity" min-width="80">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.checkStatus == 0
                  ? "待审核"
                  : scope.row.checkStatus == 1
                  ? "审核通过"
                  : "审核失败"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="打款状态" prop="sentQuantity" min-width="80">
          <template slot-scope="scope">
            <div>{{ scope.row.payStatus == 0 ? "未打款" : "已打款" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              v-if="tableFrom.selectType == 2"
              @click="paymentOne(scope.row.id)"
              icon="el-icon-s-finance"
              size="small"
              >打款</el-button
            >
            <el-button
              type="text"
              @click="lookView(scope.row.id)"
              size="small"
              icon="el-icon-info"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <!--用户详情-->
    <FinanceBillDetails
      @closeDrawer="closeDrawer"
      :drawer="drawer"
      :info="info"
      ref="userDetails"
    />
  </div>
</template>

<script>
import {
  platformBillPageList,
  platformBillAdd,
  platformOrderCount,
  financeBillBatch,
  financeExportExcel,
  platformSupplierDetail,
} from "@/api/finance/finance";
import { getIndex } from "@/utils/other";
import FinanceBillDetails from "@/components/financeBillDetails/financeBillDetails";
import moment from "moment";
import { exportExcel } from "../../../assets/utils/exportExcel";
import { getSupplierListAll } from "@/api/supplier/supplier";

export default {
  name: "ProductClassify",
  components: {
    FinanceBillDetails,
  },
  data() {
    return {
      moren: require("@/assets/images/bjt.png"),
      isChecked: false,
      listLoading: false,
      supplierList: [],
      selectionId: [], //选中得id
      checkStatusOption:[
        
        {
          label: "待审核",
          value: 0,
        },
        {
          label: "审核通过",
          value: 1,
        },{
          label: "审核失败",
          value: 2,
        },
      ],
      payStatusOption:[
         {
          label: "未打款",
          value: 0,
        },
        {
          label: "已打款",
          value: 1,
        }
      ],
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        supplierId: "",
        time: [],
        selectType: 1,
      },
      form: {},
      details: {},
      drawer: false,
      info: [],
    };
  },
  mounted() {
    this.getSupplierList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    //获取供应商列表
    getSupplierList() {
      getSupplierListAll({}).then((res) => {
        this.supplierList = res.data;
        this.tableFrom.supplierId = res.data[0].id;
        this.getList();
      });
    },
    //获取头部数据
    getDateDetails() {
      platformOrderCount({
        type: 1,
        supplierId: this.tableFrom.supplierId,
      }).then((res) => {
        if (res.data) {
          this.details = res.data;
        } else {
          this.details = {};
        }
      });
    },
    closeDrawer() {
      this.drawer = false;
    },
    lookView(val) {
      platformSupplierDetail(val).then((res) => {
        this.info = res.data;
        console.log("1231--", res.data);
        this.drawer = true;
      });
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.tableFrom.supplierId = this.supplierList[0].id;
      this.selectType = 1;
      this.getList();
    },
    //多选得id
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const data = [];
      this.multipleSelection.map((item) => {
        data.push(item.id);
      });
      this.selectionId = data;
    },
    //批量
    beforSettlement(id) {
      this.$confirm(`是否结算`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            platformBillAdd({
              id: this.tableFrom.supplierId,
              orderIds: [id],
              type: 1,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.getList();
              }
            });
          }
        },
      });
    },
    //批量审批
    batchExamine(val) {
      if (this.selectionId.length == 0) {
        this.$message.error("请选择操作数据");
        return;
      }
      financeBillBatch({
        ids: this.selectionId,
        object: 1,
        type: val,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getList();
        }
      });
    },

    paymentOne(val) {
      this.$confirm(`是否打款`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            financeBillBatch({
              ids: [val],
              object: 1,
              type: 3,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功");
                this.getList();
              }
            });
          }
        },
      });
    },
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      platformBillPageList({
        ...this.tableFrom,
        time: "",
        type: 1,
        id: this.tableFrom.supplierId,
      })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    //导出
    exportFun() {
      exportExcel("/order/web/finance/platform/bill/exportExcel", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        type: 1,
        id: this.tableFrom.supplierId,
        responseType: "blob",
        exportExcelName: "对账单列表",
      });
    },
    handleExcelBlob(binaryData) {
      const blob = new Blob([binaryData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // Excel MIME类型

      // 创建一个URL指向Blob对象
      const url = window.URL.createObjectURL(blob);

      // 创建隐藏的可下载链接
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = "output.xlsx"; // 自定义下载的Excel文件名

      // 触发点击下载
      document.body.appendChild(link);
      link.click();

      // 下载完成后清理
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },

    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.tableFrom.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.elCard {
  width: 23.5%;
}
.price {
  font-size: 30px;
}
</style>
